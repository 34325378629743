import { Card, Empty, Icon, Input, Modal, Radio, Row, Spin, Table, Col, Alert } from 'antd';
import moment from 'moment';
import React, { Component, useState, useRef } from 'react';
import * as apiUtils from "../../../common/utils/apiGateway";
import { dataGet, getLocationData, notifyApiError, scanResponseDataMap, trimByConfig } from '../../../common/utils/utils';
import ScanService from '../../../services/inbound/scan.service';
import { SoundNotificationService } from '../../../services/sound.service';

import BulkOperation from '../../shared-components/bulk-operation';
import ScanSticker from '../../shared-components/scan-sticker';
import WaybillLink from '../../shared-components/waybill-link';
import MwebScanUI from './mweb.scan';
import './scan.scss';
import { Constants } from '../../../common/utils/constants';
import ScanActionRequiredPopupModal from '../../shared-components/scan-action-required-modal';

const soundNotificationService = new SoundNotificationService;

class NewScanUI extends Component {
    constructor(props) {
        super(props);
        this.state = {
            columns: [
                {
                    title: 'AWB No',
                    dataIndex: 'awbNo',
                    key: 'awbNo',
                    render: (data, row) => (<WaybillLink>{data}</WaybillLink>)
                },
                {
                    title: 'Source',
                    dataIndex: 'source',
                    key: 'source',
                },
                {
                    title: 'Destination',
                    dataIndex: 'destination',
                    key: 'destination',
                },
                {
                    title: 'Customer',
                    dataIndex: 'customer',
                    key: 'customer',
                },
                {
                    title: 'Flow Type',
                    dataIndex: 'flowType',
                    key: 'flowType',
                },
                {
                    title: 'Scan Time',
                    dataIndex: 'scanTime',
                    key: 'scanTime',
                    render: data => (<div>{moment(data).format('DD/MM/YYYY HH:mm')} <br /> <small>{moment(data).fromNow()}</small> </div>)
                },
                {
                    title: 'Created On',
                    dataIndex: 'createdOn',
                    key: 'createdOn',
                    render: data => (<div>{moment(data).format('DD/MM/YYYY HH:mm')} <br /> <small>{moment(data).fromNow()}</small> </div>)
                },
            ],
            waybillScanEnabled: true,
            barcodeEnabled: false,
            ornEnabled: false,
            selectedScanView: '',
            scanEntry: true,
            inputEntryTimeList: [],
            inBoundScanning: false,
            deepLinkUrl: undefined,
            currentScanEntryTime: 0,
            scanResponseStatus: { success: false, error: false },
            scanResponse: {},
            rowsFilter: [],
            tempFilter: [],
            lastScanTime: 0,
            totalScannedItems: 0,
            sampleInscanDownloadLink: "https://loadshare-v2.s3.ap-south-1.amazonaws.com/dev/orn_bulk_upload.xlsx",
            isBarcodeScan: false
        }
        this.InputSearchRef = React.createRef();
        this.lastInputTimeRef = React.createRef();
    }

    componentDidMount() {
        this.focusInput();
        if (this.props.permissionList.waybill_scan) {
            this.radioCheck('waybillScanEnabled')
        }
        if (this.props.permissionList.orn_scan) {
            this.radioCheck('ornEnabled')
        }
    }

    componentDidUpdate(prevProps) {
        if (this.props.scanedInfo !== prevProps.scanedInfo) {
            this.consignmentScanRepsonse(this.props.scanedInfo)
        }
    }

    focusInput = () => {
        this.setState({
            inputEntryTimeList: [],
            currentScanEntryTime: new Date().getTime(),
            waybillNumber: ''
        }, () => {
            this.InputSearchRef.current.focus();
            this.lastInputTimeRef.current = Date.now();
        })
    }

    consignmentScanRepsonse = (responseData) => {
        if (responseData) {
            let scanResponseStatus;
            const scanResponse = responseData[0];

            console.log("scanResponse", scanResponse)

            let scanResponseDataCurated = {};
            if (scanResponse?.sync) {
                if (dataGet(scanResponse, "consignmentScanBO.consignment.id") != null) {
                    scanResponseStatus = { success: true, error: false };
                    let scanResponsetemp = {}
                    if (scanResponse.consignmentScanBO) {
                        scanResponsetemp = {
                            cpd: scanResponse.consignmentScanBO.consignment.expectedDeliveryDate,
                            cityName: scanResponse.consignmentScanBO.consignment.pincode.city.cityCode,
                            cityPincode: scanResponse.consignmentScanBO.consignment.pincode.zipcode,
                            consineeName: scanResponse.consignmentScanBO.consignment.customer.name,
                            consineeType: scanResponse.consignmentScanBO.consignment.paymentType,
                            scanTime: moment(),
                            waybillNo: scanResponse.awbNo,
                            pincode: scanResponse.consignmentScanBO.consignment.pincode.zipcode,
                            bookingDate: scanResponse.consignmentScanBO.consignment.bookingDate,
                            nextLocation: scanResponse.consignmentScanBO.consignment.nextLocation ? scanResponse.consignmentScanBO.consignment.nextLocation.name : "",
                            localService: scanResponse.consignmentScanBO.consignment.isLocalServiceable ? "YES" : "NO",
                            criticalShipment: scanResponse.consignmentScanBO.consignment.isCritical ? "YES" : "NO"
                        };
                    }
                    const consingment = scanResponse?.consignmentScanBO?.consignment;
                    const consingeePincode = consingment?.consignee?.pincode ? consingment.consignee.pincode : consingment.pincode;
                    const shipperPincode = consingment?.customerPickupLoc?.address?.pincode;

                    const rowsFilterNew = {
                        awbNo: scanResponse.awbNo,
                        source: getLocationData(shipperPincode),
                        destination: getLocationData(consingeePincode),
                        customer: scanResponsetemp.consineeName,
                        flowType: dataGet(consingment, "flowType", "NA"),
                        consingment,
                        scanTime: scanResponsetemp.scanTime,
                        createdOn: scanResponse.consignmentScanBO.consignment.bookingDate,
                        barcode: scanResponse?.consignmentScanBO?.barcode
                    };
                    let rowsFilter = [...this.state.rowsFilter];
                    rowsFilter.unshift(rowsFilterNew);
                    let { totalScannedItems } = this.state;
                    totalScannedItems = 0;
                    if (rowsFilter.length > 0) {
                        _.forEach(rowsFilter, (row, key) => {
                            if (_.hasIn(row, 'consingment')) {
                                totalScannedItems += 1;
                            }
                        })
                    }
                    let tempscanResponseDataCurated = {};
                    if (scanResponse.scanResponse) {
                        tempscanResponseDataCurated = scanResponseDataMap(scanResponse.scanResponse, scanResponse);
                        if (scanResponse.consignmentScanBO && scanResponse.consignmentScanBO.consignment &&
                            scanResponse.consignmentScanBO.consignment.nextLocation) {
                            tempscanResponseDataCurated["nextLocation"] = {
                                value: scanResponse.consignmentScanBO.consignment.nextLocation ?
                                    scanResponse.consignmentScanBO.consignment.nextLocation.entity?.custObject?.name + ' | ' + scanResponse.consignmentScanBO.consignment.nextLocation?.name : "",
                                key: "Next Location"
                            };
                        }

                        scanResponseDataCurated = Object.assign({}, tempscanResponseDataCurated);

                        if (scanResponseDataCurated.category.value && scanResponseDataCurated.category.value.indexOf(["HIGH", "CRITICAL"])) {
                            soundNotificationService.playSuccess();
                        }
                        else {
                            if(!isNaN(scanResponseDataCurated?.batchNumber?.value) && Number(scanResponseDataCurated?.batchNumber?.value) > 0) {
                                soundNotificationService.playGeneralSuccess();
                            }
                            else {
                                soundNotificationService.playSuccess();
                            }
                        }
                    } else {
                        soundNotificationService.playSuccess();
                    }
                    if (scanResponse?.overageStatus) {
                        scanResponseDataCurated.overageStatus = scanResponse?.overageStatus;
                    }

                    this.setState({
                        scanResponseDataCurated,
                        rowsFilter,
                        totalScannedItems,
                        scanResponse: scanResponsetemp,
                        scanResponseStatus,
                        inBoundScanning: false
                    }, this.focusInput)
                }
                else {
                    const rowsFilterNew = {
                        awbNo: scanResponse.awbNo,
                        source: "No Booking Data",
                        destination: "No Booking Data",
                        customer: "No Booking Data",
                        scanTime: moment(),
                        createdOn: moment()
                    };
                    let rowsFilter = [...this.state.rowsFilter];
                    rowsFilter.unshift(rowsFilterNew);
                    scanResponseStatus = { success: true, error: false };
                    if (scanResponse?.overageStatus) {
                        scanResponseDataCurated = {
                            awbNo: scanResponse?.awbNo,
                            overageStatus: scanResponse?.overageStatus
                        }
                        soundNotificationService.playSuccess();
                    }
                    else {
                        scanResponseStatus = { success: false, error: true };
                        soundNotificationService.playWarning();
                    }

                    this.setState({
                        scanResponseDataCurated,
                        rowsFilter,
                        scanResponseStatus,
                        inBoundScanning: false
                    }, this.focusInput)
                }
            }
            else if (scanResponse?.reasonCode == "ERR300") {
                soundNotificationService.playWarning();
                Modal.confirm({
                    icon    : false,
                    width   : this.props.isMobile ? 320 : 600,
                    title   : "Scan confirmation",
                    okText  : "Scan Waybill",
                    content : (
                        <div className='flex-column flex-gap-l align-items-stretch'>
                            <Alert type='warning' showIcon={true}
                            message={<div style={{color : "#000"}}>This waybill <strong>{scanResponse?.awbNo}</strong> is not expected in your location.</div>}
                            description={<div style={{color : "#000"}}>Current Location of waybill is 
                                            <strong> {scanResponse?.waybillLocationResponse?.expectedLocationName} </strong> 
                                            and consignee pincode 
                                            <strong> {scanResponse?.waybillLocationResponse?.destinationPincode} </strong>.
                                        </div>} />
                            <div className='font-size-m-3 flex-box justify-content-center text-bold' style={{color : "#000"}}>
                                Are you sure you want to scan this waybill?
                            </div>
                        </div>
                    ),
                    onOk : _ => {
                        Modal.destroyAll();
                        this.scanWayBill(this.state.barcodeEnabled ? 'barcode' : 'waybill', false);
                    },
                    onCancel : _ => {
                        this.setState({ inBoundScanning: false }, this.focusInput);
                        Modal.destroyAll();
                    }
                })
            } else if (scanResponse?.deepLinkUrl) {
                this.setState({ inBoundScanning: false, deepLinkUrl: scanResponse?.deepLinkUrl}, this.focusInput);
            }
            else {
                this.setState({ inBoundScanning: false }, this.focusInput);
                notifyApiError(scanResponse?.reason, 'Error');
                soundNotificationService.playWarning();
            }
        }
    }

    radioCheck = (scanType) => {
        this.setState({
            waybillScanEnabled: false,
            barcodeEnabled: false,
            ornEnabled: false,
            [scanType]: true,
            selectedScanView: scanType,
            scanResponseStatus: { success: false, error: false },
            inputPlaceholder: scanType === 'waybillScanEnabled' ? 'Waybill' : scanType === 'barcodeEnabled' ? 'Barcode' : 'ORN'
        })
    }

    scanDrsByKeyboard = (event, value) => {

        // Check if the input was typed or scanned

        const currentTime = Date.now();
        const prevTime = this.lastInputTimeRef?.current;
        const timeDifference = currentTime - prevTime;
        this.lastInputTimeRef.current = currentTime;

              if (timeDifference < 50) {
                  this.isBarcodeScan=true;
              } 
              else {
                  this.isBarcodeScan=false;
              }
        

        const waybillNumber = value.trim();
        if (!waybillNumber || waybillNumber.length === 0) {
            this.setState({
                scanEntry: false,
                waybillNumber
            })
            return;
        }

        this.setState({
            waybillNumber
        })
    }

    scanWayBillBarcode = (data) => {
        return apiUtils
            .apiRequest({
                url: `/b2b/v2/consignments/scan`,
                method: "POST",
                data
            });
    }


    scanWayBill = (entityType, enforceLocationCheck = false) => {
        if (
            this.state.waybillNumber == null ||
            this.state.waybillNumber.trim() === ""
        ) {
            notifyApiError("Waybill cannot be empty!", "Error");
            return "";
        }
        this.setState({
            inBoundScanning: true,
            scanResponseStatus: { success: false, error: false },
            scanResponseDataCurated: {}
        })
        let waybillNumber = this.state.waybillNumber.trim();
        if (this.props.config !== null) {
            waybillNumber = trimByConfig(
                waybillNumber,
                this.props.config["waybillLengthRestriction"]
            );
        }

        let scanEntry = this.isBarcodeScan;

        if (this.checkForDuplicate(waybillNumber.toUpperCase(), entityType)) {
            notifyApiError(`${entityType === 'waybill' ? 'Waybill' : 'Barcode'} was already scanned`, "Duplicate");

            this.setState({
                waybillNumber: null,
                inBoundScanning: false,
                scanResponseStatus: { success: false, error: false },
                scanResponseDataCurated: {}
            })
            return false;
        }
        let consignment;
        if (entityType === "waybill") {
            consignment = { waybillNo: waybillNumber, isBarcodeScan: false };
        } else {
            consignment = { barcode: waybillNumber, isBarcodeScan: true };
        }
        let scanInput = {
            request: [
                {
                    consignmentScan: {
                        scanType: "IN_SCAN",
                        scanInputType: scanEntry ? "BARCODE_SCANNER" : "KEYBOARD",
                        enforceLocationCheck : this.props.config && this.props.config?.enableMistypeHandling == 'true' ? enforceLocationCheck : undefined,
                        ...consignment
                    },
                    type: "IN_SCAN",
                    referenceId: waybillNumber,
                    isWaybill: this.state.waybillScanEnabled ? true : false,
                    shipmentScans: [
                        {
                            shipmentCodeType: 'BARCODE',
                            scanType: 'IN_SCAN',
                            scanInputType: scanEntry ? 'BARCODE_SCANNER' : 'KEYBOARD',
                            shipmentCode: waybillNumber
                        }
                    ],
                    enforceLocationCheck : this.props.config && this.props.config?.enableMistypeHandling == 'true' ? enforceLocationCheck : undefined
                }
            ]
        };

        if (this.props.permissionList.locationSelection) {
            scanInput["request"][0]["custPickLoc"] =
                this.customerPickupLocation && this.customerPickupLocation.value
                    ? this.customerPickupLocation.value
                    : null;
        }

        scanInput.request[0].shipmentScans = Object.assign(
            scanInput.request[0].shipmentScans,
            consignment
        );
        delete consignment.isBarcodeScan;
        scanInput.request[0].shipmentScans = Object.assign(
            scanInput.request[0].shipmentScans,
            consignment
        );

        this.props.consigmentScan(scanInput);
    }

    checkForDuplicate = (waybillNumber, entity) => {
        const isScaned = this.state.rowsFilter.find(
            consignment => entity === 'waybill' ? consignment.awbNo === waybillNumber : consignment?.barcode === waybillNumber
        );
        return !!isScaned;
    }

    closeActionModal = () => {
        this.setState({ deepLinkUrl: undefined}, this.focusInput);
    }

    render() {
        const { permissionList, application, scanLoading, isMobile } = this.props;
        const { deepLinkUrl, columns, selectedScanView, sampleInscanDownloadLink, inBoundScanning, barcodeEnabled, waybillNumber, totalScannedItems, rowsFilter, inputPlaceholder, scanResponseStatus, scanResponse } = this.state;
        if (isMobile) {
            return (
                <>
                    <MwebScanUI inBoundScanning={inBoundScanning} isAuditOn={this.props.auditStatus?.isAuditOn} InputSearchRef={this.InputSearchRef}
                        waybillNumber={waybillNumber} totalScannedItems={totalScannedItems} inputPlaceholder={inputPlaceholder}
                        scanWayBill={this.scanWayBill} scanDrsByKeyboard={this.scanDrsByKeyboard} scanResponseStatus={scanResponseStatus}
                        scanResponseDataCurated={this.state.scanResponseDataCurated} barcodeEnabled={barcodeEnabled} />
                    <ScanActionRequiredPopupModal handleClose={this.closeActionModal}
                        open={deepLinkUrl ? true : false}
                        qrText={deepLinkUrl || ''} 
                    />
                </>
            ) 
        }
        return <div className="flex-column flex-gap-xl">
            <Card title={'Scan Shipment'}>
                <div className="flex-column flex-gap-l">
                    <Radio.Group onChange={e => this.radioCheck(e.target.value)} value={selectedScanView}>
                        {
                            permissionList.waybill_scan &&
                            <Radio value={'waybillScanEnabled'}>Scan WayBill</Radio>
                        }
                        {
                            permissionList.orn_scan &&
                            <Radio value={'ornEnabled'}> Scan Reference ID </Radio>
                        }
                    </Radio.Group>

                    <div className="flex-box justify-content-space-between align-items-center">
                        <div className="flex-box justify-content-flex-start align-items-start flex-1">
                            <div className="flex-6">
                                <form onSubmit={e => { e.preventDefault(); this.scanWayBill(barcodeEnabled ? 'barcode' : 'waybill', true) }}>
                                    <Input size="large" disabled={(this.state.inBoundScanning || this.props.auditStatus?.isAuditOn)}
                                        placeholder={`Scan ${inputPlaceholder}`} onChange={e => this.scanDrsByKeyboard(e, e.target.value)}
                                        ref={this.InputSearchRef} value={waybillNumber} />
                                    <small>Use Barcode Scanner for faster process or enter manually</small>
                                </form>
                            </div>
                            {
                                permissionList.bulk_inscan && Constants.userLocationId == 5880285 &&
                                <>
                                    <div className="flex-1 flex-box justify-content-center"> <div className="element-splitter "> OR </div> </div>
                                    <div className="flex-2 flex-box justify-content-center">
                                        <BulkOperation
                                            buttonLabel="Bulk Inscan"
                                            title="Bulk Inscan"
                                            bulkType="Inscan"
                                            sampleDownloadLink={sampleInscanDownloadLink}
                                            objKey="inscan"
                                            path="bulk_inscans"
                                            syncToServerAction="UPLOAD_INSCAN"
                                            uploadMethod={(new ScanService).uploadInscan}
                                            uploadMethodParams={this.props.uploadInscanMethodParams}
                                        />
                                    </div>
                                </>
                            }
                        </div>
                        <div className="flex-box justify-content-center align-items-center flex-1">
                            <h2>Count:  {this.state.totalScannedItems}</h2>
                        </div>
                    </div>

                    {
                        this.state.scanResponseStatus?.error &&
                        <div className="font-size-l-2">
                            <div className="flex-box align-items-center flex-gap-m">
                                <div> Current Scan : </div>
                                <div className="error-color"> Added to <strong>OVERAGE</strong> </div>
                            </div>
                        </div>
                    }
                </div>
            </Card>

            {
                !this.state.inBoundScanning ?
                    this.state.scanResponseStatus.success ?
                        <ScanSticker scanResponseStatus={this.state.scanResponseStatus} isBarcode={selectedScanView}
                            scanResponseDataCurated={this.state.scanResponseDataCurated} /> :
                        <></> :
                    <div className="scan-box">
                        <Spin spinning={scanLoading}>
                        </Spin>
                    </div>
            }

            <Card title={'Previous Scan'}>
                <Table
                    bordered
                    locale={{
                        emptyText: (
                            <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
                        )
                    }}
                    loading={false}
                    pagination={
                        false
                    }
                    dataSource={rowsFilter}
                    columns={columns}
                />
            </Card>
            <ScanActionRequiredPopupModal handleClose={this.closeActionModal}
                open={deepLinkUrl ? true : false}
                qrText={deepLinkUrl || ''} 
            />
        </div>
    }
}

export default NewScanUI;