import React, { Component, useState, useEffect } from "react";
import { useSelector } from "react-redux";
import {
  fetchConsigmentUnassigned, getConsignment, lockUnlockManifest,
  updateConsignment, deleteManifest, fetchManifests, changeManifestCode,
  generateManifestCode, fetchRtoManifests
} from "./actions.js";
import "./manifest.scss";
import { connect } from "react-redux";
import { PageHeader, Alert, Row, Col, Button, Table, Card, Spin, Icon, Tooltip, Input, Checkbox, Modal, Form } from "antd";
import DataCard from "../../shared-components/data-card/DataCard";
import { getSummary } from "../../../app/appAction.js";
import activeAwbIcon0 from "./../../../assets/img/icons/images/3_1_Inventory.svg";
import activeAwbIcon1 from "./../../../assets/img/icons/images/3_2_Inventory.svg";
import activeAwbIcon2 from "./../../../assets/img/icons/images/3_3_Inventory.svg";
import * as apiUtils from "../../../common/utils/apiGateway";
import { permissionTags } from "../../../configs/permissionTags.js";
import { canAllow, checkHttpStatus, notifyApiError, notifyApiSuccess } from "../../../common/utils/utils.js";
import { Constants } from "../../../common/utils/constants.js";
import moment from "moment";
import { apiService } from "../../../common/apiService/service";

import { getReport } from "../../../app/appAction";
import _ from "lodash";

class Manifest extends Component {
  constructor(props) {

    let flowType = "FORWARD";
    if (props?.location?.pathname.includes("inventory")) {
      flowType = "FORWARD";
    } else if (props?.location?.pathname.includes("rto")) {
      flowType = "RTO";
    }
    super(props);
    let submodule = "rto"
    if (flowType === "FORWARD") {
      submodule = "inventory";
    }
    this.state = {
      manifestPermission: {
        read: canAllow(permissionTags[submodule].manifest_partner.read),
        create: canAllow(permissionTags[submodule].manifest_partner.create),
        update: canAllow(permissionTags[submodule].manifest_partner.update),
        delete: canAllow(permissionTags[submodule].manifest_partner.delete),
        reuseManifest: canAllow(permissionTags[submodule].manifest_partner.reuseManifest),

      },
      filterPayload: {
        "filters": {
          "isActive": true,
          "originatedLocIds": [Constants.userLocationId],
          "statuses": ["PENDING"],
          "flowTypes": [flowType],
          "pageNo": 0,
          "pageSize": 10,
          "fetchObjects": [
            "CONSIGNMENTS",
            "ADDRESS",
            "PINCODE",
            "LOCATIONS"
          ]
        }
      },
      showFilterModal: false,
      currentStatus: ["PENDING"],
      statuses: ["PENDING", "COMPLETED", "LINKED"],
      DataCardList: [],
      data: [],
      flowType,
      dataLoading: false,
      pageNo: 1,
      dataCardIcon: { activeAwbIcon0, activeAwbIcon1, activeAwbIcon2 },
      application: "B2C",
      searchData: undefined,
      showUnlockManifestDialog: false,
      selectedManifestData: null
    }
    this.InputSearchRef = React.createRef();
    // this.props.getSummary("INVENTORY_MANIFEST_B2C");

  }

  componentDidMount() {

    let flowType = "Inventory";
    if (this.props?.location?.pathname.includes("inventory")) {
      flowType = "Inventory";
    } else if (this.props?.location?.pathname.includes("rto")) {
      flowType = "RTO";
    }

    this.applyPagination({ pageNo: 1 });

  }

  gotoEdit = data => {

    let flowType = "inventory";
    if (this.props.location?.pathname.includes("inventory")) {
      flowType = "inventory";
    } else if (this.props.location?.pathname.includes("rto")) {
      flowType = "rto";
    }

    if (flowType == "inventory" && this.props.config?.enableRearch?.enableManifestReadOps === "true" ||
      flowType == "rto" && this.props.config?.enableRearch?.enableRtoManifestReadOps === "true") {
      this.props.history.push(`/appv2/${flowType}/form/manifest-partner/${data?.manifestCode}`);
    } else {
      this.props.history.push(`/appv2/${flowType}/form/manifest-partner/${data?.id}`);
    }

  }

  gotoDelete = data => {
    let flowType = "inventory";
    if (this.props.location?.pathname.includes("inventory")) {
      flowType = "inventory";
    } else if (this.props.location?.pathname.includes("rto")) {
      flowType = "rto";
    }



    let params = {
      request: [
        {
          manifest: {
            ...data,
            consignments: []
          },
          "action": "DELETE",
          "manifestCode": data?.manifestCode,
          "referenceId": data?.manifestCode
        }
      ]
    }

    const _this = this;
    const { confirm } = Modal;
    confirm({
      title: "Delete",
      content: <><b>ARE YOU SURE?</b><br />Do you want to delete this manifest!!!</>,
      okText: "Save Changes",
      cancelText: "Close",
      onOk: () => {
        if ((this.getFlowType() === "inventory" &&
          this.props.config?.enableRearch?.enableManifestWriteOps === "true") ||
          (this.getFlowType() === "rto" && this.props.config?.enableRearch?.enableRtoManifestWriteOps === 'true')
        ) {
          deleteManifest(data?.manifestCode)
            .then(resp => {
              notifyApiSuccess("Successfully deleted manifest", "SUCCESS");
              _this.applyPagination({ pageNo: this.state?.pageNo });
            })
            .catch(err => {
              console.error("Delete manifest failed with following error", err)
            });
        } else {
          apiUtils.apiRequest({
            url: "/b2b/v1/manifest/update",
            data: params,
            method: "POST"
          }).then(response => {

            const data = response?.data?.response?.responses[0];
            if (data?.sync) {
              notifyApiSuccess(data?.reason, "SUCCESS");

              _this.applyPagination({ pageNo: this.state?.pageNo });
            } else {
              notifyApiError(data?.reason, "Error");
            }

          })
        }

      }
    })

  }

  componentDidUpdate(prevProps, prevState) {
    const { summary } = this.props;
    const { dataCardIcon } = this.state;
    if (summary !== prevProps.summary) {
      const DataCardList = [
        {
          title: "Pending Manifest",
          documentType: "PENDING_MANIFEST_B2C",
          description: summary["PENDING_MANIFEST_B2C"]?.count,
          icon: dataCardIcon[`activeAwbIcon${0}`]
        },
        {
          title: "Added Today",
          documentType: "MANIFEST_B2C_ADDED_TODAY",
          description: summary["MANIFEST_B2C_ADDED_TODAY"]?.count,
          icon: dataCardIcon[`activeAwbIcon${1}`]
        },
        {
          title: "Pending AWB",
          documentType: "PENDING_BAG",
          description: summary["PENDING_BAG"]?.count,
          icon: dataCardIcon[`activeAwbIcon${2}`]
        }
      ]
      this.setState({
        DataCardList,
        summaryLoader: false
      })
    }
  }



  applyPagination = (filter, isReset = false, isSearched = false) => {
    this.setState({ dataLoading: true });
    if (isReset) {
      this.setState({
        pageNo: 1
      })
    }

    let params = {
      filters: {
        ...this.state.filterPayload.filters,
        ...filter
      }


    }
    if (isSearched && filter?.manifestCodes?.length > 0) {
      delete params?.filters?.statuses;
    }

    const payload = {
      pageNo: params.filters.pageNo,
      pageSize: params.filters.pageSize,
      statuses: params.filters.statuses ? params.filters.statuses : []
    }

    if (params.filters?.manifestCodes?.length > 0) {
      payload['manifestCodeInput'] = params.filters.manifestCodes[0]
    }

    if (this.getFlowType() === "rto" && this.props.config?.enableRearch?.enableRtoManifestReadOps === 'true') {

      fetchRtoManifests(payload, { showLoader: false })
        .then(resp => {
          const data = resp;
          this.setState({ data, dataLoading: false })
        })
        .catch(err => console.error("fetch RTO Manifests failed with following error", err))

    } else if (this.getFlowType() === "inventory" && this.props.config?.enableRearch?.enableManifestReadOps === 'true') {

      fetchManifests(payload, { showLoader: false })
        .then(resp => {
          const data = resp;
          this.setState({ data, dataLoading: false })
        })
        .catch(err => console.error("fetchManifests failed with following error", err))

    }
    else {
      apiUtils.apiRequest({
        url: "/b2b/v1/manifest/fetch",
        data: params,
        method: "POST"
      }).then(response => {
        const data = response?.data?.response;
        this.setState({ data, dataLoading: false })
      })
    }

  }


  getPaginatedData = (pageNo, paginatedData) => {
    this.applyPagination({ pageNo })
    this.setState({
      pageNo
    });
  }

  handleUnblock = (manifestData) => {
    this.setState({ showUnlockManifestDialog: true, selectedManifestData: manifestData })
  }

  handleUnlockCancel = () => {
    this.setState({
      showUnlockManifestDialog: false,
      selectedManifestData: null
    })
  };

  getFlowType = () => {
    let flowType = "inventory";
    if (this.props.location?.pathname.includes("inventory")) {
      flowType = "inventory";
    } else if (this.props.location?.pathname.includes("rto")) {
      flowType = "rto";
    }
    return flowType;
  }


  onChangeFilter = status => {

    this.setState({
      currentStatus: [...status]
    })
  }

  resetFilter = () => {
    const currentStatus = ["PENDING"];
    this.setState({
      currentStatus: [...currentStatus],
      pageNo: 1,
      searchData: undefined
    }, () => {
      let { filterPayload } = this.state;
      let { filters } = filterPayload;


      filterPayload = {
        filters: {
          ...filters,
          "statuses": this.state.currentStatus,
          pageNo: 1
        }
      };

      this.setState({ filterPayload, showFilterModal: false }, () => {
        this.applyPagination({ ...filterPayload.filters });
      })
    })
  }

  applyFilter = () => {
    let { filterPayload } = this.state;
    let { filters } = filterPayload;


    filterPayload = {
      filters: {
        ...filters,
        pageNo: 1,
        "statuses": this.state.currentStatus
      }
    };

    this.setState({ filterPayload, showFilterModal: false }, () => {
      this.applyPagination({ ...filterPayload.filters });
    })
  }

  downloadALL = () => {
    const reportParams = {
      resource: { question: 2143 },
      params: { location_id: Constants.userLocationId }
    }
    window.open(`/appv2/reports/manifest-listing?reportParams=${btoa(JSON.stringify(reportParams))}`);
  }


  render() {
    let flowType = "inventory";

    if (this.props.location?.pathname.includes("inventory")) {
      flowType = "inventory";
    } else if (this.props.location?.pathname.includes("rto")) {
      flowType = "rto";
    }
    const { DataCardList, dataLoading, summaryLoader, pageNo, data, showFilterModal, currentStatus, statuses, manifestPermission } = this.state;

    let columns = []

    if (
      (this.getFlowType() === "inventory" && this.props.config?.enableRearch?.enableManifestReadOps === 'true') ||
      (this.getFlowType() === "rto" && this.props.config?.enableRearch?.enableRtoManifestReadOps === 'true')
    ) {
      columns = [
        {
          title: "Manifest ID",
          dataIndex: "manifestCode",
          key: "manifestCode"
        },
        {
          title: "AWB",
          dataIndex: "shipmentCount",
          key: "consignments"
        },
        {
          title: "Next Location",
          dataIndex: "nextLocName",
          key: "nextLocation"
        },
        {
          title: "Destination",
          dataIndex: "destinationLocName",
          key: "destinationLoc"
        },
        {
          title: "Created At",
          dataIndex: "createdAt",
          key: "createdAt",
          render: (data, row) => (
            <div className="flex-box flex-column">
              <span>{row.originatedLocName}</span>
              <span>{moment(data).format("DD/MM/YYYY HH:mm")}</span>
            </div>
          )
        },
        {
          title: "Status",
          dataIndex: "manifestStatus",
          key: "manifestStatus",
        },
        {
          title: "Action",
          key: "action",
          render: data => <a href={`/appv2/print_information?consignmentId=${data?.id}&formtype=inventory_manifest_information`}
            target="_blank" rel="noopener noreferrer"> <Icon type="eye" /> </a>
        },
        {
          title: "",
          key: "action_new",
          render: data => {
            return (
              <Row gutter={[12, 12]}>
                {data.isLocked && (
                  <div>
                    <Button
                      type="primary"
                      size="small"
                      disabled={!data.unlockAllowed}
                      onClick={() => this.handleUnblock(data)}
                    >Unlock</Button>
                  </div>
                )}
                {this.state.manifestPermission?.update && !data.isLocked &&
                  <Col span={6}>
                    <Icon type="edit" onClick={() => this.gotoEdit(data)} />
                  </Col>
                }
                {this.state.manifestPermission?.delete &&
                  (((this.getFlowType() === "inventory" && this.props.config?.enableRearch?.enableManifestReadOps === 'true') ||
                    (this.getFlowType() === "rto" && this.props.config?.enableRearch?.enableRtoManifestReadOps === 'true')) ?
                    data.shipmentCount == 0 : !_.hasIn(data, "consignments"))
                  && !data.isLocked &&
                  <Col span={6}>
                    <Icon type="delete" onClick={() => this.gotoDelete(data)} />
                  </Col>
                }
              </Row>
            )
          }
        }

      ]
    }
    else {
      columns = [
        {
          title: "Manifest ID",
          dataIndex: "manifestCode",
          key: "manifestCode"
        },
        {
          title: "AWB",
          dataIndex: "consignments",
          key: "consignments",
          render: data => data ? data?.length : 0
        },
        {
          title: "Next Location",
          dataIndex: "nextLocation",
          key: "nextLocation",
          render: data => <span>{data?.name}</span>
        },
        {
          title: "Destination",
          dataIndex: "destinationLoc",
          key: "destinationLoc",
          render: data => <span>{data?.name}</span>
        },
        {
          title: "Created At",
          dataIndex: "createdAt",
          key: "createdAt",
          render: (data, row) => (
            <div className="flex-box flex-column">
              <span>{row?.originatedLoc?.name}</span>
              <span>{moment(data).format("DD/MM/YYYY HH:mm")}</span>
            </div>
          )
        },
        {
          title: "Status",
          dataIndex: "manifestStatus",
          key: "manifestStatus",
        },
        {
          title: "Action",
          key: "action",
          render: data => <a href={`/appv2/print_information?consignmentId=${data?.id}&formtype=inventory_manifest_information`}
            target="_blank" rel="noopener noreferrer"> <Icon type="eye" /> </a>
        },
        {
          title: "",
          key: "action_new",
          render: data => {
            return (
              <Row gutter={[12, 12]}>
                {data.isLocked && (
                  <div>
                    <Button
                      type="primary"
                      size="small"
                      disabled={!data.unlockAllowed}
                      onClick={() => this.handleUnblock(data)}
                    >Unlock</Button>
                  </div>
                )}
                {this.state.manifestPermission?.update && !data.isLocked &&
                  <Col span={6}>
                    <Icon type="edit" onClick={() => this.gotoEdit(data)} />
                  </Col>
                }
                {this.state.manifestPermission?.delete && !_.hasIn(data, "consignments") && !data.isLocked &&
                  <Col span={6}>
                    <Icon type="delete" onClick={() => this.gotoDelete(data)} />
                  </Col>
                }
              </Row>
            )
          }
        }

      ]
    }

    return (
      <>
        <div className="legacy-sparkline">
          <div className="legacy-sparkline-datacards" style={{ visibility: "hidden" }}>
            {
              summaryLoader ?
                <Spin spinning={summaryLoader} /> :
                DataCardList && DataCardList.length > 0 ?
                  DataCardList.map((item, index) => (
                    <div className="flex-1">
                      <DataCard {...item} />
                    </div>
                  )) :
                  <></>
            }
          </div>
          <div className="legacy-sparkline-ctas">
            {
              manifestPermission?.create &&
              <Button onClick={() => this.props.history.push(`/appv2/${flowType}/form/manifest-partner`)}
                size="large" className="lsn-btn-primary" type="primary" block={true} icon="plus">
                Create Manifest
              </Button>
            }
          </div>
        </div>
        <Card className="inventorymanifest" title={
          <Row gutter={12}>
            <Col span={12}>
              <h2>Manifest List</h2>
            </Col>
            <Col span={12}>
              <Col span={15}>
                <Input.Search
                  allowClear
                  size={"large"}
                  value={this.state.searchData}
                  disabled={dataLoading}
                  placeholder="Search Manifest ID/Destination"
                  onChange={e => this.setState({ searchData: e.target.value })}
                  onSearch={value => this.applyPagination(value && { manifestCodes: [value.trim()] }, true, true)}
                  enterButton
                />
              </Col>
              <Col span={3}>
                <Tooltip title="Download">
                  <Button block size="large" type="primary" onClick={this.downloadALL} disabled={dataLoading}>
                    <Icon type="download" />
                  </Button>
                </Tooltip>
              </Col>
              <Col span={3}>
                <Tooltip placement="top" title={"Reload Manifest List"}>
                  <Button block size={"large"} onClick={() => this.resetFilter()} type="primary" disabled={dataLoading}>
                    <Icon type="reload" />
                  </Button>
                </Tooltip>
              </Col>
              <Col span={3}>
                <Tooltip placement="top" title={"Filter"}>
                  <Button block size={"large"} onClick={e => { this.setState({ showFilterModal: true }) }} type="primary" disabled={dataLoading}>
                    <Icon type="filter" />
                  </Button>
                </Tooltip>
              </Col>
              {/* <Col span={2} offset={1}>
            <Tooltip placement="top" title={'Create booking'}>
              <Dropdown overlay={<Menu>
                <Menu.Item key={1} onClick={e => this.props.history.push(`/appv2/inbound/form`)}>
                  <span>
                    <Tooltip placement="top" title={'Create booking'}>
                      FTL
                    </Tooltip>
                  </span>
                </Menu.Item>
                <Menu.Item key={2} onClick={e => this.props.history.push(`/appv2/inbound/ptl/form`)}>
                  <span>
                    <Tooltip placement="top" title={'Create booking'}>
                      PTL
                    </Tooltip>
                  </span>
                </Menu.Item>
              </Menu>} trigger={['click']} placement="bottomCenter">
                <Button size={'large'} type="primary">
                  <Icon type="plus" />
                </Button>
              </Dropdown>
            </Tooltip>
          </Col> */}
            </Col>
          </Row>
        }
        // extra={actionBtns}
        >
          <Table size="middle"
            columns={columns}
            dataSource={data?.manifests}
            loading={dataLoading}
            pagination={{
              onChange: this.getPaginatedData,
              total: data?.totalCount,
              current: pageNo,
              pageSize: data?.pageSize
            }}
            bordered
          />

          <Modal
            title={"Filter"}
            visible={showFilterModal}
            closable={false}
            footer={[
              <Button type="default" onClick={() => this.setState({ showFilterModal: false })}>Cancel</Button>,
              <Button type="primary" onClick={this.applyFilter}>Apply</Button>,
              <Button type="danger" onClick={this.resetFilter}>Reset</Button>
            ]}
          >
            <h3>Status</h3>
            <Checkbox.Group
              options={statuses}
              value={currentStatus}
              onChange={this.onChangeFilter}
            />

          </Modal>
          <UnLockManifestModal
            showUnlockManifestDialog={this.state.showUnlockManifestDialog}
            handleUnlockCancel={this.handleUnlockCancel}
            selectedManifestData={this.state.selectedManifestData}
            applyPagination={this.applyPagination}
            history={this.props.history}
            showReuseButton={this.state.manifestPermission.reuseManifest}
            flowType={this.getFlowType()}
          />
        </Card>
      </>
    )
  }
}

const UnLockManifestModal = (props) => {
  const manifestCode = props.selectedManifestData ? props.selectedManifestData.manifestCode : "";
  const manifestId = props.selectedManifestData ? props.selectedManifestData.id : null;
  const [inputManifestCode, setInputManifestCode] = useState("");
  const [isReplaceTriggered, setIsReplaceTriggered] = useState(false);
  const [newManifestCode, setNewManifestCode] = useState("");
  const [isManifestCodeReplaced, setIsManifestCodeReplaced] = useState(false);
  const [finalManifestCode, setFinalManifestCode] = useState("");
  const [showRelaceAndReuseOption, setShowReplaceAndReuseOption] = useState({
    isSameCodeAsSelected: false,
    showError: false,
  });
  const [isLoading, setIsLoading] = useState(false);
  const [shouldCheckForEquality, setShouldCheckForEquality] = useState(false);
  const [disableInputManifestCode, setDisableInputManifestCode] = useState(false);
  const [enableRearch, setEnableRearch] = useState(false);
  const [enableRtoRearch, setEnableRtoRearch] = useState(false);

  const { showUnlockManifestDialog, handleUnlockCancel, showReuseButton, flowType } = props;

  const appConfig = useSelector(state => state?.app?.configurations);

  useEffect(() => {

    if (flowType === "inventory" && appConfig?.enableRearch?.enableManifestWriteOps === 'true') {
      setEnableRearch(true)
    } else if (flowType === "rto" && appConfig?.enableRearch?.enableRtoManifestWriteOps === 'true') {
      setEnableRtoRearch(true)
    }

  }, [appConfig])

  const handleChangeManifestCode = (e) => {
    const value = (e.target.value).trim();
    setInputManifestCode(value);
  };

  const handleChangNewManifestCode = (e) => {
    const value = (e.target.value).trim();
    setNewManifestCode(value);
  };

  const handleScanManifestCode = (e) => {
    const value = (e.target.value).trim();
    setInputManifestCode(value);
    setShouldCheckForEquality(true);
  };

  const handleManifestCodeOnEnter = (e) => {
    if (e.key === "Enter") {
      const value = (e.target.value).trim();
      setInputManifestCode(value);
      setShouldCheckForEquality(true);
    }
  };

  useEffect(() => {
    if (inputManifestCode && shouldCheckForEquality) {
      handleCheckManifestCodeForEquality();
      setShouldCheckForEquality(false);
    }
  }, [inputManifestCode, shouldCheckForEquality]);

  const handleCheckManifestCodeForEquality = () => {
    if ((manifestCode).trim() === inputManifestCode) {
      setShowReplaceAndReuseOption({
        ...showRelaceAndReuseOption,
        isSameCodeAsSelected: true,
        showError: false
      });
      setDisableInputManifestCode(true);
    } else {
      setShowReplaceAndReuseOption({
        ...showRelaceAndReuseOption,
        isSameCodeAsSelected: false,
        showError: true
      });
      setDisableInputManifestCode(false);
    }
  };

  const getLockManifestPath = (code) => {

    let redirectUrl = null

    if (appConfig?.enableRearch?.enableManifestReadOps === 'true' ||
      appConfig?.enableRearch?.enableRtoManifestReadOps === "true") {
      redirectUrl = `/appv2/${flowType}/form/manifest-partner/${code ? code : manifestCode}?isLocked=true`;
    } else {
      redirectUrl = `/appv2/${flowType}/form/manifest-partner/${manifestId}?isLocked=true`;
    }

    return redirectUrl;
  };

  const handleReuseManifest = () => {
    unlockManifest(manifestCode, false);
  }

  const lockManifest = code => {
    if (enableRearch || enableRtoRearch) {

      const payload = {
        manifestCode: code,
        shouldLock: false
      }

      setIsLoading(true);

      lockUnlockManifest(payload)
        .then(resp => {
          notifyApiSuccess(resp);
          const redirectionPath = getLockManifestPath(code);
          props.history.push(redirectionPath);
          setIsLoading(false);
        }).catch(err => {
          console.error("lockManifest failed with following error", err)
          setIsLoading(false);
        })

    }
    else {
      apiUtils.apiRequest({
        url: "/b2b/v1/manifest/lock",
        method: "POST",
        data: {
          manifestCode: code,
          shouldLock: false
        }
      }).then(response => {
        if (response && response.data) {
          notifyApiSuccess(response.data.status.message);
          const redirectionPath = getLockManifestPath();
          props.history.push(redirectionPath);
        } else {
          const redirectionPath = `/appv2/${flowType}/dashboard/manifest-partner`;
          props.history.push(redirectionPath);
        }
        setIsLoading(false);
      }).catch(err => {
        notifyApiError(err, "ERROR");
        setIsLoading(false);
        const redirectionPath = getLockManifestPath();
        props.history.push(redirectionPath);
      })
    }
  }

  const unlockManifest = async (code, performReplaceManifest = false) => {
    setIsLoading(true);
    if (performReplaceManifest) {

      if (enableRearch || enableRtoRearch) {

        const reqBody = {
          oldManifestCode: manifestCode,
          newManifestCode: code,
        }

        changeManifestCode(reqBody)
          .then(response => {
            notifyApiSuccess("Manifest Code replaced successfully")
            lockManifest(code);
            setIsLoading(false);
          })
          .catch(err => {
            console.error("changeManifestCode failed with following error", err)
            setIsLoading(false);
          })

      } else {
        replaceManifestCode(code)
          .then(response => {
            if (checkHttpStatus(response?.data?.status)) {
              lockManifest(code);
            }
          })
      }
    }
    else {
      lockManifest(code);
    }

  };

  const replaceManifestCode = async (newId) => apiUtils.apiRequest({
    url: "/b2b/v1/manifest/replace",
    method: "POST",
    data: {
      oldManifestCode: manifestCode,
      newManifestCode: newId
    }
  })

  const handleGenerateManifestId = () => {

    if (enableRearch || enableRtoRearch) {

      generateManifestCode()
        .then(response => {
          setNewManifestCode("");
          setFinalManifestCode(response.manifestCode);
          setIsManifestCodeReplaced(true);
        })
        .catch(err => {
          console.error("generateManifestCode api failed with following error", err)
        })

    } else {

      apiUtils.apiRequest({
        url: "/b2b/v1/fetch/code?code=MANIFEST",
        method: "GET"
      }).then(response => {
        const newlyGeneratedManifestCode = response && response.data && response.data.response && response.data.response.code ? response.data.response.code : "";
        setNewManifestCode("");
        setFinalManifestCode(newlyGeneratedManifestCode);
        setIsManifestCodeReplaced(true);
      }).catch(err => {
        notifyApiError(err, "ERROR")
      })

    }

  };

  const triggerReplaceOption = () => setIsReplaceTriggered(true);

  const handleEnterNewManifestId = (e) => {
    if (e.key === "Enter") {
      setFinalManifestCode((e.target.value).trim());
      setNewManifestCode("");
      setIsManifestCodeReplaced(true);
    }
  }

  const handleClose = () => {
    handleUnlockCancel();
    setInputManifestCode("");
    setIsReplaceTriggered(false);
    setNewManifestCode("");
    setIsManifestCodeReplaced(false);
    setShowReplaceAndReuseOption({
      ...showRelaceAndReuseOption,
      isSameCodeAsSelected: false,
      showError: false
    });
    setIsLoading(false);
    setDisableInputManifestCode(false);
    setShouldCheckForEquality(false);
  };

  return (
    <Modal
      title="Unlock Manifest"
      visible={showUnlockManifestDialog}
      closable={true}
      onCancel={handleClose}
      onOk={() => unlockManifest(finalManifestCode, true)}
      okText="Save"
      width="720px"
      okButtonProps={{ disabled: !isManifestCodeReplaced || !finalManifestCode, loading: isLoading }}
    >
      <div style={{ display: "flex", flexDirection: "column" }}>
        <Form layout="vertical">
          <Form.Item label="Scan Manifest ID" style={{ marginBottom: "12px" }}>
            <Input
              allowClear
              placeholder="Scan Manifest Code"
              value={inputManifestCode}
              onChange={handleChangeManifestCode}
              addonAfter={
                <span
                  style={{
                    cursor: disableInputManifestCode ? "no-drop" : "pointer",
                    pointerEvents: disableInputManifestCode ? "none" : "auto"
                  }}
                  onClick={handleCheckManifestCodeForEquality}
                >
                  <Icon type="barcode" />&nbsp;Scan
                </span>
              }
              onPressEnter={handleScanManifestCode}
              onKeyDown={handleManifestCodeOnEnter}
              disabled={disableInputManifestCode}
            />
          </Form.Item>
        </Form>
        <div style={{ minHeight: "96px", border: "1px solid #e6e7e7", borderRadius: "4px" }}>
          {!isReplaceTriggered
            ? (
              <>
                {showRelaceAndReuseOption.isSameCodeAsSelected ? (
                  <div className="flex-box flex-column flex-gap-m" style={{ padding: "10px" }}>
                    <div>Manifest ID {manifestCode}</div>
                    <div className="flex-box justify-content-flex-start">
                      <Button type="primary" onClick={triggerReplaceOption}>Replace Manifest</Button>
                      {showReuseButton && (<Button type="default" onClick={handleReuseManifest}>Reuse Manifest</Button>)}
                    </div>
                  </div>
                ) : null}
                {
                  !showRelaceAndReuseOption.isSameCodeAsSelected && showRelaceAndReuseOption.showError && (
                    <div className="flex-box flex-column flex-gap-m" style={{ padding: "10px" }}>
                      <Alert type="error" message="The scanned code and the selected code don't match!" />
                    </div>
                  )
                }
              </>
            )
            : (
              <div className="flex-box flex-column flex-gap-m" style={{ padding: "10px" }}>
                <div>Replace ID <span style={{ color: "#3b5a98" }}>Manifest ID {manifestCode}</span></div>
                <Form layout="vertical">
                  <Form.Item label="Scan Manifest ID" style={{ marginBottom: "12px" }}>
                    <Input
                      allowClear
                      placeholder="Enter New ID"
                      value={newManifestCode}
                      onChange={handleChangNewManifestCode}
                      // onPressEnter={handleReplaceNewManifestCodeOnEnter}
                      onKeyDown={handleEnterNewManifestId}
                      addonAfter={
                        <span style={{ cursor: "pointer" }} onClick={handleGenerateManifestId} >
                          <Icon type="reload" />
                        </span>
                      }
                    />
                  </Form.Item>
                </Form>
                {isManifestCodeReplaced && finalManifestCode && (<Alert type="success" message={`New Manifest ID: ${finalManifestCode}`} />)}
              </div>
            )
          }
        </div>
      </div>

    </Modal>
  )
}

const mapStateToProps = (state, ownProps) => ({
  awbData: state.inventoryAwb,
  selectedConsigment: state.inventoryAwb.selectedConsigment,
  updateConsigment: state.inventoryAwb.updateConsigment,
  PaginatedConsigment: state.inventoryAwb.PaginatedConsigment,
  summary: state.app.summary,
  config: state.app.configurations,
  locations: state.header.locationHeaderDetails,
  header: state?.header,
});
const mapDispatchToProps = dispatch => ({
  getSummary: page => dispatch(getSummary(page)),
  fetchConsigmentUnassigned: (payload, isReset) => dispatch(fetchConsigmentUnassigned(payload, isReset)),
  getConsignment: payload => dispatch(getConsignment(payload)),
  updateConsignment: payload => dispatch(updateConsignment(payload)),
  getReports: report => dispatch(getReport(report))
});
export default connect(mapStateToProps, mapDispatchToProps)(Manifest);