import axios from "axios";
import LoaderService from "../../services/loader";
import { checkHttpStatus, generateRequestHeaders } from "./utils";
import { apiUrl, devEnvType } from "../../environments/node-react-environment";
import { notifyApiError } from "../utils/utils";
import { rearchLocalApiUrl } from "../../environments/node-react-environment.local";

export const apiService = {
    get: (url, params = {}) => {
        return apiCustomRequest({
            url,
            method: "GET",
            ...params
        })
    },
    post: (url, data, params = {}) => {
        return apiCustomRequest({
            url,
            method: "POST",
            data,
            ...params
        })
    },
    put: (url, data, params = {}) => {
        return apiCustomRequest({
            url,
            method: "PUT",
            data,
            ...params
        })
    },
    delete: (url, params = {}) => {
        return apiCustomRequest({
            url,
            method: "DELETE",
            ...params
        })
    },
}

export const apiCustomRequest = (params = {}) => new Promise((resolve, reject) => {
    var { url, method, data, queryParams, headers, abortController, doNotDisplayErrorMessage} = params;

    const showLoader = params?.showLoader ?? true

    if (!url) {
        reject("Unknown endpoint!");
        return;
    }
    if (!method) {
        method = "GET"
    }

    var requestHeaders = generateRequestHeaders(headers);

    var requestUrl = url.trim();

    if (requestUrl.indexOf(apiUrl) < 0) {
        requestUrl = `${apiUrl}${url}`
    }

    if (devEnvType == "local" && (url.indexOf("fm/") > -1 || url.indexOf("lm/") > -1)) {
        requestUrl = `${rearchLocalApiUrl}${url}`
    } else if (devEnvType == "local" && (url.indexOf("hub/") > -1 || url.indexOf("hub/") > -1)) {
        requestUrl = `${rearchLocalApiUrl}${url}`
    }

    if (showLoader) {
        LoaderService.toggleLoaderOn();
    }

    axios({
        url     : requestUrl,
        method  : method,
        data    : method == "GET" ? undefined : data,
        params  : method == "GET" || method === "PUT" ? queryParams : undefined,
        headers : requestHeaders,
        signal  : abortController,
        timeout : 5000
    })
        .then(response => {
            if (showLoader) {
                LoaderService.toggleLoaderOff();
            }
            resolve(response.data);
        })
        .catch(error => {
            if (showLoader) {
                LoaderService.toggleLoaderOff();
            }
            if (!checkHttpStatus(error?.response?.status)) {
                return;
            }
            if (!doNotDisplayErrorMessage) {
                var message = error.response?.data?.errors;
                if (Array.isArray(message) && message.length > 0) {
                    message = message.join(". ");
                }
                if (typeof (message) === "string") {
                    notifyApiError(message);
                }
            }
            reject(error.response?.data);
        })

});