import { Modal } from "antd";
import debounce from "lodash/debounce";
import { useCallback, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { TRIP_ACTION_CONFIRM_MESSAGE, TRIP_ACTION_SUCCESS_MESSAGE, TRIP_UPDATE_ACTIONS } from "../../trips.utils";
import { dispatchTrip, executeTripAction, markTripArrived } from "../trips.service";

export default function TripActionModal({ tripId, tripCode, onSuccessFunction, onCancelFunction, action }) {

    const [modalVisible, SetModalVisibile] = useState(false);
    const [loading, SetLoading] = useState(false);
    const config = useSelector(state => state?.app?.configurations);

    console.log("TripActionModal config", config)

    useEffect(() => {
        if (tripId) {
            SetModalVisibile(true);
        }
        else {
            SetModalVisibile(false);
        }
    }, [tripId]);

    const callMarkArrived = _ => {
        SetLoading(true);

        if (config?.enableRearch?.outboundTripWriteOps === 'true' && action === "DISPATCH") {
            dispatchTrip(tripCode)
                .then(_ => {
                    SetLoading(false);
                    SetModalVisibile(false);
                    Modal.success({
                        title: (
                            typeof TRIP_ACTION_SUCCESS_MESSAGE[action] == "function" ?
                                TRIP_ACTION_SUCCESS_MESSAGE[action](tripCode) :
                                <>Successfully {action} trip - <strong>{tripCode}</strong></>
                        ),
                        onOk: _ => {
                            if (typeof onSuccessFunction === "function") {
                                onSuccessFunction();
                            }
                            Modal.destroyAll();
                        }
                    })
                })
                .catch((e) => {
                    console.error(e)
                    SetLoading(false);
                    SetModalVisibile(false);
                })

        } else if (config?.enableRearch?.markTripArrived === 'true' && action === "MARK_ARRIVED") {

            markTripArrived(tripCode)
                .then(_ => {
                    SetLoading(false);
                    SetModalVisibile(false);
                    Modal.success({
                        title: (
                            typeof TRIP_ACTION_SUCCESS_MESSAGE[action] == "function" ?
                                TRIP_ACTION_SUCCESS_MESSAGE[action](tripCode) :
                                <>Successfully {action} trip - <strong>{tripCode}</strong></>
                        ),
                        onOk: _ => {
                            if (typeof onSuccessFunction === "function") {
                                onSuccessFunction();
                            }
                            Modal.destroyAll();
                        }
                    })
                })
                .catch((e) => {
                    console.error(e)
                    SetLoading(false);
                    SetModalVisibile(false);
                })

        } else {
            executeTripAction({
                tripId: tripId,
                action: action
            })
                .then(_ => {
                    SetLoading(false);
                    SetModalVisibile(false);
                    Modal.success({
                        title: (
                            typeof TRIP_ACTION_SUCCESS_MESSAGE[action] == "function" ?
                                TRIP_ACTION_SUCCESS_MESSAGE[action](tripCode) :
                                <>Successfully {action} trip - <strong>{tripCode}</strong></>
                        ),
                        onOk: _ => {
                            if (typeof onSuccessFunction === "function") {
                                onSuccessFunction();
                            }
                            Modal.destroyAll();
                        }
                    })
                })
        }
    }

    const cancelMarkArrived = _ => {
        if (typeof onCancelFunction === "function") {
            onCancelFunction();
        }
    }

    const debounceAction = useCallback(
        debounce(() => callMarkArrived(), 800), []
    )

    const handleOnOk = () => {
        if (action == TRIP_UPDATE_ACTIONS.MARK_ARRIVED) {
            debounceAction()
        } else {
            callMarkArrived()
        }
    }

    return (
        <Modal visible={modalVisible} title="Mark Trip Arrived"
            closable={false} cancelText="Cancel" okText="Confirm"
            okButtonProps={{ loading: loading }} cancelButtonProps={{ loading: loading }}
            onOk={handleOnOk} onCancel={cancelMarkArrived}>
            {
                typeof TRIP_ACTION_CONFIRM_MESSAGE[action] == "function" ?
                    TRIP_ACTION_CONFIRM_MESSAGE[action](tripCode) :
                    <>Are you sure you want to {action} trip - <strong>{tripCode}</strong>?</>
            }
        </Modal>
    )
}